'use strict';

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/database';
import 'firebase/compat/storage';
import 'firebase/compat/functions';
import page from 'page';

const utils = require('../functions/geral/utilsFunctions')
const Enum = require('./Enum')

export default class FirebaseHelper {

  constructor() {
    // Firebase SDK.
    this.database = firebase.database();
    this.storage = firebase.storage();
    this.auth = firebase.auth();
    this.project = firebase.name
    this.functions = firebase.app().functions('southamerica-east1');
    this.apiMAG = this.functions.httpsCallable('apiMAG')
    this.apiPortal = this.functions.httpsCallable('apiPortal')
    this.apiContratacao = this.functions.httpsCallable('apiContratacao')
    this.apiQueue = this.functions.httpsCallable('apiQueue')
    this.apiMaisAmigos = this.functions.httpsCallable('apiMaisAmigos')
    this.apiAdesao = this.functions.httpsCallable('apiAdesao')
    this.apiPrevidenciaDigital = this.functions.httpsCallable('apiPrevidenciaDigital')
    this.apiAresCrm = this.functions.httpsCallable('apiAresCrm')
    this.apiSimulacao = this.functions.httpsCallable('apiSimulacao')
    // Firebase references that are listened to.
    this.firebaseRefs = [];
  }


  updatePublicProfile() {
    let user = firebase.auth().currentUser;
    let displayName = user.displayName;
    let imageUrl = user.photoURL;

    // If the main profile Pic is an expiring facebook profile pic URL we'll update it automatically to use the permanent graph API URL.
    if (imageUrl && (imageUrl.indexOf('lookaside.facebook.com') !== -1 || imageUrl.indexOf('fbcdn.net') !== -1)) {
      // Fid the user's Facebook UID.
      const facebookUID = user.providerData.find((providerData) => providerData.providerId === 'facebook.com').uid;
      imageUrl = `https://graph.facebook.com/${facebookUID}/picture?type=large`;
      user.updateProfile({ photoURL: imageUrl }).then(() => {
      });
    }

    if (!displayName) {
      displayName = 'Anonymous';
    }
    let searchFullName = displayName.toLowerCase();
    let searchReversedFullName = searchFullName.split(' ').reverse().join(' ');
    /*try {
      searchFullName = latinize(searchFullName);
      searchReversedFullName = latinize(searchReversedFullName);
    } catch (e) {
      console.error(e);
    }*/

    if (imageUrl) {
      const updateData = {
        profile_picture: imageUrl
      };
      updateData._search_index = {
        full_name: searchFullName,
        reversed_full_name: searchReversedFullName,
      };
      this.database.ref(`/login/${user.uid}`).update(updateData).then(() => {
        //console.log('Public profile updated.');
      });
    }
    return this.getTermoServicoSettings(user.uid)
  }


  getTermoServicoSettings(uid) {
    return this.database.ref(`/login/${uid}/termo_servico`).once('value');
  }


  setTermoServicoSettings(uid, settings) {
    const uri = `/login/${uid}/termo_servico`;
    this.database.ref(uri).set(settings);
  }


  removeFromSearch(uid) {
    this.database.ref(`login/${uid}/_search_index`).remove();
  }

  async getCampanhas() {
    let body = {
      acao: 'getCampanhas',
    }
    return this.apiPortal({ body: body }).then((response) => {
      return response.data.sucesso ? response.data.data : false
    })
  }


  async getSegmento(segmento) {
    let body = {
      acao: 'getSegmento',
      segmento: segmento
    }
    return this.apiPortal({ body: body }).then((response) => {
      return response.data.sucesso ? response.data.data : false
    })
  }


  removerCampanha(chave, index) {
    let body = {
      acao: 'removerCampanha',
      chave: chave,
      index: index
    }
    return this.apiPortal({ body: body }).then((response) => {
      return response.data.sucesso ? response.data.data : false
    })
  }


  gravaDadosPrimeiroLogin(primeiroLogin, uid) {
    let body = {
      acao: 'gravaDadosPrimeiroLogin',
      primeiroLogin: primeiroLogin,
      uid: uid
    }
    return this.apiPortal({ body: body }).then((response) => {
      return response.data.sucesso ? response.data.data : false
    })
  }


  gravaLoginSucesso(uid, deviceInfo) {
    let body = {
      acao: 'gravaLoginSucesso',
      deviceInfo: deviceInfo,
      uid: uid
    }
    return this.apiPortal({ body: body }).then((response) => {
      return response.data.sucesso ? response.data.data : false
    })
  }


  resetEmailVerified(uid) {
    let body = {
      acao: 'resetEmailVerified',
      uid: uid
    }
    return this.apiPortal({ body: body }).then((response) => {
      return response.data.sucesso ? response.data.data : false
    })
  }


  async validaRegistroLogin(uid) {
    let body = {
      acao: 'validaRegistroLogin',
      uid: uid
    }

    return this.apiPortal({ body: body }).then((response) => {
      return response.data.sucesso ? response.data.data : false
    }).catch((e) => {
      return false
    })

  }


  async enviarEmailLinkValidacao(tipoEnvio, emailDestino, nome) {
    let usr = firebase.auth().currentUser
    let ref
    if (tipoEnvio === 'firebase') {
      let ret = await usr.sendEmailVerification()
        .then(() => {
          //console.log('=> Email Enviado')
          return true
        }).catch((e) => {
          //Erros.registraErro(usr.uid, 'emailVer', 'enviarEmailLinkValidacao', JSON.stringify(e))
          return false
        })
      return ret
    } else {  //envio próprio de e-mails de verificação - grava no BD para trigger de e-mail enviar
      let idToken = await usr.getIdToken(/* forceRefresh */ true)
        .then((idToken) => {
          //console.log('=> Token OK')
          return idToken
        }).catch((e) => {
          //Erros.registraErro(usr.uid, 'tkn', 'enviarEmailLinkValidacao', JSON.stringify(e))
          return false
        });

      if (!idToken) {
        return false
      }
      let dataEnvio = utils.dateFormat(new Date(), true, true, false)
      //grava e-mail a ser enviado
      let projetoId = sessionStorage.nomeProjeto
      let linkWeb = `https://southamerica-east1-${projetoId}.cloudfunctions.net/validaEmailLinkKey?k=${idToken}`
      ref = this.database.ref(`login/${usr.uid}/emails`)
      let jsonEmail = {}
      jsonEmail[dataEnvio] = {
        emailDestinatario: emailDestino,
        assunto: 'Previdência Digital | Validação de e-mail',
        corpo: '',
        linkWeb: linkWeb,
        nome: nome,
        corpoHtml: 'validaEmailLink.html',
        enviar: true
      }
      ref.update(jsonEmail)
      //console.log('=> Email Enviado')
      return true
    }
  }

  async confirmaCelular(celular, uid, user) {
    let usr = firebase.auth().currentUser
    let idToken = await usr.getIdToken(/* forceRefresh */ true)
      .then((idToken) => {
        //console.log('=> Token OK')
        return idToken
      }).catch((e) => {
        //Erros.registraErro(usr.uid, 'tkn', 'enviarEmailLinkValidacao', JSON.stringify(e))
        return false
      });

    if (!idToken) {
      return false
    }

    let listaChaves = {}
    let ref = this.database.ref('settings/primeiro_login/lista_email_valido')
    await ref.orderByChild('email').equalTo(user.email).once('value')
      .then((snapshot) => {
        if (snapshot.val() === null) {
          return false
        } else {

          let i = 0
          snapshot.forEach((snap) => {
            //nomeP1 = snap.child('nomeParticipantePrincipal').val()
            listaChaves = {
              celularLista: snap.child('celular').val()
            }
            i++
          })
          if (Object.keys(listaChaves).length > 0) {
            return listaChaves
          } else {
            return false
          }
        }
      })

    let numeroLimpo = listaChaves.celularLista.replace(/\D/g, '');

    // Verifica se o número tem o formato esperado
    if (numeroLimpo.length === 11) {
      // Mantém os primeiros dois dígitos e os últimos quatro
      let parteVisivel = numeroLimpo.substring(0, 2);
      let parteOculta = numeroLimpo.substring(7, 11);
      numeroLimpo = `(${parteVisivel}) *****-${parteOculta}`;
    } else {
      return "Formato de telefone inválido";
    }

    const retObjeto = {
      numero: numeroLimpo
    }
    return retObjeto
  }

  async enviarWhatsLinkValidacao(celular, uid, user) {

    let usr = firebase.auth().currentUser
    let idToken = await usr.getIdToken(/* forceRefresh */ true)
      .then((idToken) => {
        //console.log('=> Token OK')
        return idToken
      }).catch((e) => {
        //Erros.registraErro(usr.uid, 'tkn', 'enviarEmailLinkValidacao', JSON.stringify(e))
        return false
      });

    if (!idToken) {
      return false
    }

    let listaChaves = {}
    let ref = this.database.ref('settings/primeiro_login/lista_email_valido')
    await ref.orderByChild('email').equalTo(user.email).once('value')
      .then((snapshot) => {
        if (snapshot.val() === null) {
          return false
        } else {

          let i = 0
          snapshot.forEach((snap) => {
            //nomeP1 = snap.child('nomeParticipantePrincipal').val()
            listaChaves = {
              celularLista: snap.child('celular').val(),
              plano: snap.child('plano').val(),
              isRP: snap.child('isRP').val() ? snap.child('isRP').val() : null
            }
            i++
          })
          if (Object.keys(listaChaves).length > 0) {
            return listaChaves
          } else {
            return false
          }
        }
      })

    const celularListaChaves = listaChaves.celularLista;

    if (celularListaChaves) {
      let body = {
        acao: 'enviarWhatsLinkValidacao',
        celular: listaChaves.celularLista,
        uid: uid,
        plano: listaChaves.plano,
        projeto: sessionStorage.nomeProjeto,
        idToken: idToken
      }

      let response = await this.apiPortal({ body: body })
      if (response.data.sucesso === true) {
        return "Confirmação enviada com sucesso"
      }
    } else {
      return "Falha no envio da mensagem";
    }
  }

  async enviarEmailContratacao(nome) {
    let usr = firebase.auth().currentUser

    return this.getLoginUsuario(usr.uid).then((login) => {
      let dataEnvio = utils.dateFormat(new Date(), true, true, false)
      let ref = this.database.ref(`login/${usr.uid}/emails`)

      //grava e-mail a ser enviado
      let jsonEmail = {}
      jsonEmail[dataEnvio] = {
        emailDestinatario: login.email_principal,
        assunto: 'Mais Futuro | Passo a Passo - Assinatura Contrato Empréstimo D4 SIGN',
        corpo: `
Olá ${nome},

Em breve enviaremos a você um e-mail com o seu contrato digital de empréstimo. Segue abaixo o passo a passo de como validar rapidamente a documentação:

1. Abrir o e-mail: empréstimo – mais futuro (sign@d4sign.com.br)
   (fique atento, seu servidor pode ter enviado esse e-mail para a pasta de SPAM)

2. Insira o código: emprestimo2021

3. Clique no botão ASSINAR (botão verde no lado esquerdo da sua tela)

4. Anexe uma foto segurando o seu RG ou CNH, de forma que estejam legíveis.
   Esse processo pode ser feito via celular através do QR Code que aparecerá na sua tela; ou através de um link a ser enviado para o seu e-mail.
   Obs.: caso necessário, você deverá ajustar as configurações do seu dispositivo, de modo a permitir o acesso do aplicativo à câmera.

5. Preencha nos campos abertos seu: Nome | Data de Nascimento | CPF.

6. Você poderá fazer sua assinatura manualmente na sua tela de smartphone, com seu mouse no computador, ou clicar no ícone Fonte (A), onde aparecerá seu nome, o qual terá validade como assinatura.

7. Clicar para finalizar assinatura no botão indicado.

Após o processo de aprovação do contrato digital, você receberá uma cópia no seu e-mail.

Atenciosamente,
Mais Futuro | Equipe de Relacionamento`,
        nome: nome,
        enviar: true
      }
      ref.update(jsonEmail)
      return true
    })

  }


  async getUsuarioListaParticipacoes(user, tipoLogin, celular, email) {
    let emailBusca = (user.email && user.email !== '') ? user.email : email
    let celularBusca = user.phoneNumber
    if (!celularBusca || celularBusca === '') {
      celularBusca = celular.replace('(', '').replace(')', '').replace(' ', '').replace('-', '')
    }
    let vlrBuscaLogin = tipoLogin === 'celular' ? celularBusca : emailBusca
    //primeiro verifica se já logou e pega do login caso exista
    let ref = this.database.ref('login')
    let ret = false
    await ref.orderByChild(tipoLogin + '_principal').equalTo(vlrBuscaLogin).limitToFirst(2) //últimos 2 porque pode ocorrer de já ter o registro incompleto do próprio uid
      .once('value', (snapshot) => {
        if (snapshot.val() !== null) { //já achou dados do usuário no registro de Logins, portanto, pega as chaves de lá
          snapshot.forEach((snap) => {
            if (ret === '' && snap.key !== user.uid) { //pega só o primeiro, desde que não seja o próprio uid
              ret = snap.val()
              ret['uid_vinculado'] = snap.key
            }
          })
        } else { //não achou no login, então tenta pegar do registro de primeiro login
          ret = false
        }
      }).catch((e) => {
        //erro de acesso (denied) - não encontrou nenhum nó e então dá erro de acesso a raiz login
        //console.log('===> e', e)
        ret = false
      })
    return ret
  }

  //15
  async getUsuarioListaParticipacoesPrimeiroLogin(user, celular, emailAlternativo) {
    let celularBusca = user.phoneNumber
    if (!celularBusca || celularBusca === '') {
      celularBusca = celular.replace('(', '').replace(')', '').replace(' ', '').replace('-', '')
    }
    //busca se email do usuário está cadastrado como email conhecido de participante em dados de primeiro login
    let p0 = new Promise((resolve) => {
      if (user.email) {
        let ref = this.database.ref('settings/primeiro_login/lista_email_valido')
        return ref.orderByChild('email').equalTo(user.email).once('value')
          .then((snapshot) => {
            if (snapshot.val() === null) {
              return resolve(false)
            } else {
              let listaChaves = {}
              let i = 0
              snapshot.forEach((snap) => {
                //nomeP1 = snap.child('nomeParticipantePrincipal').val()
                listaChaves[snap.child('chave').val()] = {
                  nome: snap.child('nome').val(),
                  plano: snap.child('plano').val(),
                  segmento: snap.child('segmento').val(),
                  dtnasc: snap.child('data_nasc').val(),
                  email: user.email,
                  cpfPrincipal: snap.child('cpf').val(),
                  isRP: snap.child('isRP').val() ? snap.child('isRP').val() : null
                }
                i++
              })
              if (Object.keys(listaChaves).length > 0) {
                return resolve(listaChaves)
              } else {
                return resolve(false)
              }
            }
          })
      } else {
        return resolve(false)
      }
    })

    let p1 = new Promise((resolve) => {
      if (celularBusca !== '') {
        let ref = this.database.ref('settings/primeiro_login/lista_email_valido')
        return ref.orderByChild('celular').equalTo(celularBusca).once('value')
          .then((snapshot) => {
            if (snapshot.val() === null) {
              return resolve(false)
            } else {
              let listaChaves = {}
              snapshot.forEach((snap) => {
                //nomeP2 = snap.child('nomeParticipantePrincipal').val()
                listaChaves[snap.child('chave').val()] = {
                  nome: snap.child('nome').val(),
                  plano: snap.child('plano').val(),
                  segmento: snap.child('segmento').val(),
                  dtnasc: snap.child('data_nasc').val(),
                  email: snap.child('email').val(),
                  cpfPrincipal: snap.child('cpf').val(),
                  isRP: snap.child('isRP').val() ? snap.child('isRP').val() : null
                }
              })
              return resolve(Object.keys(listaChaves).length > 0 ? listaChaves : false)
            }
          })
      } else {
        return resolve(false)
      }
    })

    //busca pelo email alternativo
    let p2 = new Promise((resolve) => {
      if (emailAlternativo && user.email !== emailAlternativo) {
        let ref = this.database.ref('settings/primeiro_login/lista_email_valido')
        return ref.orderByChild('email').equalTo(emailAlternativo).once('value')
          .then((snapshot) => {
            if (snapshot.val() === null) {
              return resolve(false)
            } else {
              let listaChaves = {}
              snapshot.forEach((snap) => {
                //nomeP1 = snap.child('nomeParticipantePrincipal').val()
                listaChaves[snap.child('chave').val()] = {
                  nome: snap.child('nome').val(),
                  plano: snap.child('plano').val(),
                  segmento: snap.child('segmento').val(),
                  dtnasc: snap.child('data_nasc').val(),
                  email: emailAlternativo,
                  cpfPrincipal: snap.child('cpf').val(),
                  isRP: snap.child('isRP').val() ? snap.child('isRP').val() : null
                }
              })
              if (Object.keys(listaChaves).length > 0) {
                return resolve(listaChaves)
              } else {
                return resolve(false)
              }
            }
          })
      } else {
        return resolve(false)
      }
    })

    return Promise.all([p0, p1, p2])
      .then((retPromises) => {
        let achouEmailLogin, achouEmailAlternativo, achouCelular
        //se não achou nem na lista de emails nem na lista de celulares, nem como email alternativo
        if (!retPromises[0] && !retPromises[1] && !retPromises[2]) {
          return false
        } else {
          achouEmailLogin = retPromises[0] ? true : false
          achouEmailAlternativo = retPromises[2] ? true : false
          achouCelular = retPromises[1] ? true : false
          //faz um merge das chaves de cada uma das buscas: 1. email logado + 2. número do celular + 3. email alternativo
          let listaChavesRetorno = {}
          let chave_principal
          if (retPromises[0]) {
            let listaChaves = retPromises[0]
            Object.keys(retPromises[0]).forEach((key) => {
              let itemListaChaves = listaChaves[key]
              if (!chave_principal) { // && itemListaChaves.nome.toUpperCase() === user.displayName.toUpperCase()) {
                chave_principal = {
                  chave: key,
                  nome: itemListaChaves.nome
                }
              }
              listaChavesRetorno[key] = itemListaChaves
            })
          }
          if (achouCelular) {
            let listaChaves = retPromises[1]
            Object.keys(retPromises[1]).forEach((key) => {
              //Atenção: para telefone, se o nome da lista não bater com o nome do incluído no cadastramento não considera
              //questão de segurança
              let itemListaChaves = listaChaves[key]
              if (!achouEmailLogin && itemListaChaves.nome.toUpperCase() === user.displayName.toUpperCase()) {
                if (!chave_principal) {
                  chave_principal = {
                    chave: key,
                    nome: itemListaChaves.nome
                  }
                }
                if (listaChavesRetorno[key] === undefined) { //se ainda não foi incluido
                  listaChavesRetorno[key] = itemListaChaves
                }
              }
            });
          }
          if (achouEmailAlternativo) {
            let listaChaves = retPromises[2]
            Object.keys(retPromises[2]).forEach((key) => {
              //Atenção: para email alternativo, se o nome da lista não bater com o nome do incluído no cadastramento não considera
              //questão de segurança
              let itemListaChaves = listaChaves[key]
              if (!achouEmailLogin && itemListaChaves.nome.toUpperCase() === user.displayName.toUpperCase()) {
                if (!chave_principal) {
                  chave_principal = {
                    chave: key,
                    nome: itemListaChaves.nome
                  }
                }
                if (listaChavesRetorno[key] === undefined) { //se ainda não foi incluido
                  listaChavesRetorno[key] = itemListaChaves
                }
              }
            });
          }
          if (!chave_principal && Object.keys(listaChavesRetorno).length > 0) { //se não identificou por nome ou por email, pega a primeira
            let chave = Object.keys(listaChavesRetorno)[0] ? Object.keys(listaChavesRetorno)[0] : ''
            chave_principal = {
              chave: chave,
              nome: user.displayName
            }
          }
          return {
            listaChavesRetorno: Object.keys(listaChavesRetorno).length > 0 ? listaChavesRetorno : null,
            chave_principal: chave_principal,
            tipoListaChaves: { achouEmailLogin: achouEmailLogin, achouEmailAlternativo: achouEmailAlternativo, achouCelular: achouCelular }
          }
        }
      })
  }

  //16
  async getUsuarioListaParticipacoesDados(cpf) {
    let body = {
      acao: 'getUsuarioListaParticipacoesDados',
      cpf: cpf
    }
    return this.apiPortal({ body: body }).then((response) => {
      return response.data.sucesso ? response.data.data : false
    })
  }

  getSimuladorEmprestimoSettings(plano) {
    let body = {
      acao: 'getSimuladorEmprestimoSettings',
      plano: plano
    }
    return this.apiPortal({ body: body }).then((response) => {
      return response.data.sucesso ? response.data.data : false
    }).catch((e) => {
      return false
    })
  }

  getSimuladorRendaSettings(plano) {
    let body = {
      acao: 'getSimuladorRendaSettings',
      plano: plano
    }
    return this.apiPortal({ body: body }).then((response) => {
      return response.data.sucesso ? response.data.data : false
    }).catch((e) => {
      return false
    })
  }

  getSimuladorSeguroSettings(plano) {
    let body = {
      acao: 'getSimuladorSeguroSettings',
      plano: plano
    }
    return this.apiPortal({ body: body }).then((response) => {
      return response.data.sucesso ? response.data.data : false
    }).catch((e) => {
      return false
    })
  }

  getParametrosProfissaoCbo(profissaoCbo) {
    let body = {
      acao: 'getParametrosProfissaoCbo',
      profissaoCbo: profissaoCbo
    }
    return this.apiPortal({ body: body }).then((response) => {
      return response.data.sucesso ? response.data.data : false
    }).catch((e) => {
      return false
    })
  }

  //20
  async getHistoricoValores(valores, plano) {
    let historico = []
    let hoje = utils.dateFormat(new Date(), false, false, false, false, false)
    let listaValoresPortal = valores.map((v) => {
      if (v.origem.toLowerCase() !== "aporte") {
        return v
      }
    }).filter((hist) => hist)
    listaValoresPortal.forEach((cobranca) => {
      let database = cobranca.anoMes.slice(-4) + '-' + cobranca.anoMes.slice(0, 2) + '-01'
      let status = 'AGUARDANDO PAGAMENTO'
      let pago = false
      let origem = (cobranca.origem === 'PAGAMENTOAVULSO' ? 'CONTRIBUIÇÃO' : (cobranca.origem || 'CONTRIBUIÇÃO')).toUpperCase()
      let vencimento = (cobranca.dataVencimento || database).substring(0, 10)
      if (cobranca.valor > 0) {
        let formaCobranca = (cobranca.formaCobranca || '').toUpperCase() || 'BOLETO'
        let vencido = true
        if (cobranca.pago) {
          status = 'PAGO'
          pago = true
        } else if (cobranca.status) {
          pago = (cobranca.status || '').toUpperCase() === 'PAGO' //só para garantir que não virá com cobranca.pago === false e status==="PAGO"... Se vier corrige
          let diffParaVencimento = utils.diffDatasEmDias(vencimento, hoje)
          if (origem === 'DÉBITO EM CONTA') {
            vencido = diffParaVencimento >= 1
          } else if (diffParaVencimento > 28) {
            vencido = diffParaVencimento > 28
          } else if (formaCobranca === 'DÉBITO EM CONTA' && diffParaVencimento >= 1) {
            vencido = diffParaVencimento
          } else {
            vencido = false
          }

          if ((cobranca.status || '').toUpperCase() === 'AGUARDANDO PAGAMENTO' && !vencido && !cobranca.urlStorageBoleto && plano!=='OABPrev-PR') {
            status = 'EM PROCESSAMENTO'
          } else if ((cobranca.status || '').toUpperCase() === 'AGUARDANDO PAGAMENTO' && vencido && formaCobranca === 'DÉBITO EM CONTA') {
            status = 'AGUARDANDO PAGAMENTO DÉBITO EM CONTA'
          } else {
            status = (cobranca.status || '').toUpperCase()
          }
        }
        //para ajuste de informação em tela no App
        if (origem === 'DÉBITO EM CONTA') {
          origem = 'CONTRIBUIÇÃO'
        } else if (origem === 'PAGAMENTO AVULSO') {
          origem = 'CONTRIBUIÇÃO'
        }


        if (status !== 'CANCELADO') {

          let encontradoIndex = historico.findIndex(item => item.anoMes === cobranca.anoMes && item.regraCobrancaId === cobranca.regraCobrancaId);
          if (encontradoIndex !== -1) {
            // Se encontrado, some o valor ao objeto existente
            historico[encontradoIndex].valor += cobranca.valor;
          } else {
            historico.push({
              anoMes: cobranca.anoMes,
              chave: cobranca.chave,
              pago: pago,
              status: status,
              urlStorageBoleto: cobranca.urlStorageBoleto || '',
              valor: cobranca.valor,
              database: database,
              origem: origem,
              dataVencimento: vencimento,
              codJuno: cobranca.codJuno || '',
              regraCobrancaId: cobranca.regraCobrancaId || '',
              cobrancaId: cobranca.cobrancaId || '',
              numeroDePagamento: cobranca.numeroPagamento || '',
              chavePIX: cobranca.codigoPix || '',
              tipoCobranca: formaCobranca || '',
              id: cobranca.id || '',
              vencido: vencido,
              transactionId: cobranca.transactionId || null,
              isADI: database >= '2022-08-01' //Leandro Furini - definido que será 100% ADI após o mês de Agosto, primeiro mês de arrecadação total pelo ADI (Boleto + Débitos + Arrecadações empresa - inclusive J)
            })
          }
        }
      }
    })
    //ordena decrescente
    historico = historico.sort((a, b) => new Date(b.database) - new Date(a.database))
    //return historico.length > 0 ? historico.slice(0, 18) : null  //Leandro Furini em 05/Set/22 - acertado com o Thiago para mostrar em tela apenas os 12 últimos meses
    //Juliano Kosny em 04/Mar/24 - hotfix para exibir 18 últimos meses para plano ABEPOMPrev, posteriormente criar configuração no banco.
    var competencias = 12
    if (plano === 'ABEPOMPrev')
      competencias = 18
    var result = []
    var add = 0
    var anomes = null
    for(var i=0; i<historico.length; i++) {
      if(!anomes || (anomes !== historico[i].anoMes)) {
          anomes = historico[i].anoMes
          add++
      }
      if(add <= competencias)
        result.push(historico[i])
    }
    return result.length > 0 ? result : null
  }

  //21
  async getDadosSimuladorRenda(chave, uid) {
    let usuario
    if (sessionStorage.participante && sessionStorage.participante !== "") {
      usuario = JSON.parse(sessionStorage.participante)
    } else {
      usuario = await this.getParticipantePorChave(chave, uid)
      sessionStorage.participante = JSON.stringify(usuario)
    }
    let simuladorRendaSettings = await this.getSimuladorRendaSettings(usuario.data.cadastro.dados_plano.plano)
    let minimoContribuicao = Math.ceil(usuario.data.valores.contribParticipante)
    if (minimoContribuicao < simuladorRendaSettings.contribuicaoMinima) {
      minimoContribuicao = simuladorRendaSettings.contribuicaoMinima
    }
    let maximoContribuicao = minimoContribuicao + (simuladorRendaSettings.step_contribuicao * 40)

    let dataNasc = usuario.data.cadastro.informacoes_pessoais.nascimento
    let today = utils.dateFormat(new Date(), false, false, true, false, false)
    let dataAdesao = usuario.data.cadastro.dados_plano.data_adesao ? usuario.data.cadastro.dados_plano.data_adesao : today
    let idade = utils.idade_hoje(new Date(dataNasc.replace(/(\d{2})\/(\d{2})\/(\d{4})/, "$2/$1/$3")))
    let idadeApos = utils.calculaIdadeApos(dataNasc, dataAdesao, idade)
    if (simuladorRendaSettings.idade_beneficio < idadeApos) {
      simuladorRendaSettings.idade_beneficio = idadeApos
    }

    let dadosSimuladorRenda = {
      usr_tipo_plano: usuario.data.cadastro.dados_plano.tipo_plano,
      taxa_anual_simulacao: simuladorRendaSettings.taxa_anual,
      taxa_anual_renda: simuladorRendaSettings.taxa_anual_renda,
      perc_crescimento_contrib: simuladorRendaSettings.perc_crescimento_contrib || 0,
      titulo: 'Defina sua</br>contribuição</br>mensal',
      minimoContribuicaoPlano: simuladorRendaSettings.contribuicaoMinima,
      minimoContribuicao: minimoContribuicao,
      contribuicaoFixa: usuario.data.valores.contribParticipantePlanoPatrocinado,
      contribuicaoPatronal: usuario.data.valores.contribEmpresa,
      maximoContribuicao: maximoContribuicao,
      stepContribuicao: simuladorRendaSettings.step_contribuicao,
      reservaTotalAtual: usuario.data.valores.reservaTotalAtual,
      reservaTotalFutura: usuario.data.valores.reservaTotalFutura,
      rendaMensalFutura: usuario.data.valores.rendaMensalFutura,
      usr_dtnasc: usuario.data.cadastro.informacoes_pessoais.nascimento,
      idadeBeneficio: simuladorRendaSettings.idade_beneficio,
      idadeHoje: idade,
      chave: chave,
      uid: uid,
      matricula: usuario.data.cadastro.dados_plano.matricula,
      plano: usuario.data.cadastro.dados_plano.plano,
      stepEntrada: 1//(usuario.home.usr_contribuicao.acao || { acao: {qtd_steps_entrada:''}}).qtd_steps_entrada
    }
    return dadosSimuladorRenda
  }

  //22
  async getDadosSimuladorEmprestimo(chave, uid) {
    let usuario
    if (sessionStorage.participante && sessionStorage.participante !== "") {
      usuario = JSON.parse(sessionStorage.participante)
    } else {
      usuario = await this.getParticipantePorChave(chave, uid)
      sessionStorage.participante = JSON.stringify(usuario)
    }
    let idade = utils.idade_hoje(new Date(usuario.data.cadastro.informacoes_pessoais.nascimento.replace(/(\d{2})\/(\d{2})\/(\d{4})/, "$2/$1/$3")))
    let simuladorEmprestimoSettings = await this.getSimuladorEmprestimoSettings(sessionStorage.plano)
    let limite = 0
    if (((usuario.elegibilidade || {}).emprestimo || {}).saldoTotalResgatavel) {
      limite = usuario.elegibilidade.emprestimo.saldoTotalResgatavel
    }
    limite = limite * simuladorEmprestimoSettings.perc_limite_concessao
    let bloqueio_emprestimo_recente = false
    if (usuario.data.valores.historicoEmprestimo) {
      bloqueio_emprestimo_recente = Object.keys(usuario.data.valores.historicoEmprestimo).length > 0 && Object.keys(usuario.data.valores.historicoEmprestimo).length < simuladorEmprestimoSettings.qtd_meses_minimo_refinanc
    }

    let dadosSimuladorEmprestimo = {
      bloqueio: idade < 18,
      bloqueio_emprestimoRecente: bloqueio_emprestimo_recente,
      bloqueio_saldoInsuficiente: limite < simuladorEmprestimoSettings.valor_minimo_concessao || limite < usuario.data.valores.saldoEmprestimo,
      chave: chave,
      dia_vencimento: simuladorEmprestimoSettings.dia_vencimento,
      emprestimoSolicitado: '',
      fundo_risco: simuladorEmprestimoSettings.fundo_risco,
      indice_anterior: simuladorEmprestimoSettings.indice_anterior,
      matricula: usuario.data.cadastro.dados_plano.matricula,
      plano: usuario.data.cadastro.dados_plano.plano,
      pre_aprovado: limite,
      qtd_dias_maximo_iof: simuladorEmprestimoSettings.qtd_dias_maximo_iof,
      saldo_devedor: usuario.data.valores.contratoEmprestimo.saldoEmprestimo || 0,
      taxa_adm: simuladorEmprestimoSettings.taxa_adm,
      taxa_iof: simuladorEmprestimoSettings.taxa_iof,
      taxa_iof_adicional: simuladorEmprestimoSettings.taxa_iof_adicional,
      taxa_mensal: simuladorEmprestimoSettings.taxa_mensal,
      taxa_mensal_12: simuladorEmprestimoSettings.taxa_mensal_12,
      titulo: "Simulador </br>de Empréstimo",
      uid: uid,
    }
    return dadosSimuladorEmprestimo
  }

  async getQuestionarioDPS(plano) {
    try {
      let data = {
        idApi: 'questionario',
        body: '1',
        metodo: 'GET',
        plano: plano
      }
      let response = await this.apiMAG(data)
      if (!response.data.sucesso) {
        return null
      } else {
        return response.data.response
      }
    } catch (e) {
      return e
    }
  }

  async postPropostaSeguro(proposta, chave, uid, origem, idContratacao, plano) {
    try {
      let data = {
        idApi: 'proposta',
        body: proposta,
        metodo: 'POST',
        chave: chave,
        uid: uid,
        origem: origem,
        idContratacao: idContratacao,
        plano: plano
      }
      let response = await this.apiMAG(data)
      if (!response.data.sucesso) {
        return null
      } else {
        return response.data
      }
    } catch (e) {
      return e
    }
  }

  async getParametrosSimuladorMAG(usuario, plano, solicita_prazo_cobertura) {
    let nascimento, prazo_cobertura_morte, prazo_cobertura_invalidez
    //incluído por Leandro: por alguma questão da integração com a P.D. algumas datas de nascimento estão no formato AAAA-MM-DD
    if (!usuario.data.cadastro.informacoes_pessoais.nascimento.includes('-')) {
      let nascimentoArray = usuario.data.cadastro.informacoes_pessoais.nascimento.split('/')
      nascimento = `${nascimentoArray[2]}-${nascimentoArray[1]}-${nascimentoArray[0]}`
    } else {
      nascimento = usuario.data.cadastro.informacoes_pessoais.nascimento
    }
    if (usuario.data.cadastro.informacoes_pessoais.profissao && usuario.data.cadastro.informacoes_pessoais.profissao.cbo) {
      let simulacao = {
        proponente: {
          tipoRelacaoSeguradoId: 1,
          nome: usuario.data.cadastro.informacoes_pessoais.nome,
          cpf: usuario.data.cadastro.informacoes_pessoais.cpf.replace('.', '').replace('.', '').replace('-', ''),  //sem digito
          dataNascimento: nascimento,
          profissaoCbo: usuario.data.cadastro.informacoes_pessoais.profissao.cbo,
          sexoId: usuario.data.cadastro.informacoes_pessoais.sexo === "Masculino" ? 1 : 2, //Masculino 1;Feminino 2
          uf: (usuario.data.cadastro.endereco || {}).estado || 'PR',
          declaracaoIRId: 1
        },
        periodicidadeCobrancaId: 30,
        prazoCerto: 30,
        prazoPagamentoAntecipado: 10,
        prazoDecrescimo: 10
      }

      let body = { simulacoes: [] }

      if(!solicita_prazo_cobertura) {
        body.simulacoes.push({...simulacao})
      } else {
        if(!((usuario.data.cadastro || {}).dados_seguros || {}).prazo_cobertura_morte) {
          prazo_cobertura_morte = 30
        } else {
          prazo_cobertura_morte = usuario.data.cadastro.dados_seguros.prazo_cobertura_morte
        }
        if(!((usuario.data.cadastro || {}).dados_seguros || {}).prazo_cobertura_invalidez) {
          prazo_cobertura_invalidez = 30
        } else {
          prazo_cobertura_invalidez = usuario.data.cadastro.dados_seguros.prazo_cobertura_invalidez
        }
        simulacao.prazoCerto = prazo_cobertura_morte
        body.simulacoes.push({...simulacao})
        let prazosDiferentes = prazo_cobertura_morte !== prazo_cobertura_invalidez
        if(prazosDiferentes) {
          simulacao.prazoCerto = prazo_cobertura_invalidez
          body.simulacoes.push({...simulacao})
        }
      }

      let data = {
        idApi: 'simulacao',
        body: body,
        metodo: 'POST',
        plano: plano
      }
      let response = await this.apiMAG(data)
      if (!response.data.sucesso) {
        return null
      } else {
        if(response.data.response.Mensagens.length > 0) {
          return {Mensagens: response.data.response.Mensagens, sucesso: false, bloqueio: {mensagens: true}}
        } else {
          for(var i=0; i<response.data.response.Valor.simulacoes.length; i++) {
            for(var j=0; j<response.data.response.Valor.simulacoes[i].produtos.length; j++) {
              let invalidez = response.data.response.Valor.simulacoes[i].produtos[j].coberturas.find((inv) => {
                return inv.descricao.toUpperCase().includes('INVALIDEZ') && Number(prazo_cobertura_invalidez) === Number(inv.prazoCerto)
              })
              if(invalidez)
                response.data.response.fator_invalidez = invalidez.premioBase
              let morte = response.data.response.Valor.simulacoes[i].produtos[j].coberturas.find((inv) => {
                return inv.descricao.toUpperCase().includes('MORTE') && Number(prazo_cobertura_morte) === Number(inv.prazoCerto)
              })
              if(morte)
                response.data.response.fator_morte = morte.premioBase
            }
          }
          response.data.response.prazo_cobertura_morte = prazo_cobertura_morte
          response.data.response.prazo_cobertura_invalidez = prazo_cobertura_invalidez
          return response.data.response
        }
      }
    } else {
      return null
    }
  }

  async getDadosSimuladorSeguro(chave, uid, plano) {
    let simuladorSeguroSettings = await this.getSimuladorSeguroSettings(plano)
    let idadeLimiteContratacao = await  this.getParamentrosSeguro(plano) //  Busca a idade do participante para parametro idade minima de contratação
    //console.log('simuladorSeguroSettings',simuladorSeguroSettings)
    let usuario
    if (sessionStorage.participante && sessionStorage.participante !== "") {
      usuario = JSON.parse(sessionStorage.participante)
    } else {
      usuario = await this.getParticipantePorChave(chave, uid)
      sessionStorage.participante = JSON.stringify(usuario)
    }
    let idade = utils.idade_hoje(new Date(usuario.data.cadastro.informacoes_pessoais.nascimento.replace(/(\d{2})\/(\d{2})\/(\d{4})/, "$2/$1/$3")))
    let p0 = this.getFatorSimuladorSeguro(idade, plano)
    let p1 = this.getParametrosSimuladorMAG(usuario, plano, simuladorSeguroSettings.solicita_prazo_cobertura)
    let p2
    if ((usuario.data.cadastro.informacoes_pessoais.profissao || {}).cbo) {
      p2 = this.getParametrosProfissaoCbo(usuario.data.cadastro.informacoes_pessoais.profissao.cbo)
    } else {
      p2 = ''
    }
    const retPromises = await Promise.all([p0, p1, p2])
    let fator_idade_seguro = retPromises[0]
    let parametrosSimuladorMAG = retPromises[1]
    console.log('FirebaseHelper.parametrosSimuladorMAG', parametrosSimuladorMAG)

    if(!parametrosSimuladorMAG) {
      parametrosSimuladorMAG = {
        Mensagens:['Não foi possível carregar, tente mais tarde!']
      }
    }

    console.log('FirebaseHelper.parametrosSimuladorMAG', parametrosSimuladorMAG)
    if(parametrosSimuladorMAG && parametrosSimuladorMAG.Mensagens.length > 0) {
      return parametrosSimuladorMAG
    }
    //console.log('parametrosSimuladorMAG', parametrosSimuladorMAG)
    let parametrosProfissaoCbo = retPromises[2]
    let ticketMinimo
    let paramMAG_Invalidez = new Object()
    let paramMAG_Morte = new Object()
    if (parametrosSimuladorMAG) {
      ticketMinimo = parametrosSimuladorMAG.Valor.simulacoes[0].ticketMinimo
      paramMAG_Invalidez = parametrosSimuladorMAG.paramMAG_Invalidez
      paramMAG_Morte = parametrosSimuladorMAG.paramMAG_Morte
    }
    if (Object.keys(paramMAG_Invalidez).length === 0 && usuario.data.cadastro.dados_plano.plano === "ABEPOMPrev") {
      let produtoInv = parametrosSimuladorMAG.Valor.simulacoes[0].produtos.find((prod) => prod.idProduto === simuladorSeguroSettings.invalidezid)
      paramMAG_Invalidez.limite = produtoInv.coberturas[0].limite
      paramMAG_Invalidez.capitalBase = produtoInv.coberturas[0].capitalBase
      paramMAG_Invalidez.idProduto = produtoInv.idProduto
      paramMAG_Invalidez.idCobertura = produtoInv.coberturas[0].id
      paramMAG_Invalidez.descricao = produtoInv.descricao
    }
    if (Object.keys(paramMAG_Morte).length === 0 && usuario.data.cadastro.dados_plano.plano === 'ABEPOMPrev') {
      let produtoMor = parametrosSimuladorMAG.Valor.simulacoes[0].produtos.find((prod) => prod.idProduto === simuladorSeguroSettings.morteid)
      paramMAG_Morte.limite = produtoMor.coberturas[0].limite
      paramMAG_Morte.capitalBase = produtoMor.coberturas[0].capitalBase
      paramMAG_Morte.idProduto = produtoMor.idProduto
      paramMAG_Morte.idCobertura = produtoMor.coberturas[0].id
      paramMAG_Morte.descricao = produtoMor.descricao
    }
    let coberturaMorte = (usuario.data.valores.coberturaMorte === undefined || usuario.data.valores.coberturaMorte === 0) ? 0 : usuario.data.valores.coberturaMorte
    let minimoMorte = coberturaMorte
    let entradaMorte
    let entradaInvalidez
    if (usuario.projetoVida) {
      entradaMorte = usuario.projetoVida.coberturas.acao.valor_morte_entrada
      entradaInvalidez = usuario.projetoVida.coberturas.acao.valor_invalidez_entrada
    } else {
      entradaMorte = usuario.data.valores.coberturaMorte
      entradaInvalidez = usuario.data.valores.coberturaInvalidez
    }
    let maximoMorte = paramMAG_Morte.limite ? paramMAG_Morte.limite : 0
    let stepMorte = simuladorSeguroSettings.step_morte
    let maximoSemSDPSMorte = 0
    let coberturaInvalidez = (usuario.data.valores.coberturaInvalidez === undefined || usuario.data.valores.coberturaInvalidez === 0) ? 0 : usuario.data.valores.coberturaInvalidez
    let minimoInvalidez = coberturaInvalidez
    let maximoInval = paramMAG_Invalidez.limite ? paramMAG_Invalidez.limite : 0
    let stepInvalidez = simuladorSeguroSettings.step_invalidez
    let maximoSemDPSInvalidez = 0
    let profissao = false
    let valorAtual = (usuario.data.valores.contribRisco === undefined) ? 0 : usuario.data.valores.contribRisco
    if (usuario.data.cadastro.informacoes_pessoais.profissao) {
      if (maximoMorte === 0) {
        if (parametrosProfissaoCbo && parametrosProfissaoCbo.length > 0) {
          maximoMorte = parametrosProfissaoCbo[0].teto
        } else {
          maximoMorte = 1500000
        }
      }
      if (maximoInval === 0) {
        if (parametrosProfissaoCbo && parametrosProfissaoCbo.length > 0) {
          maximoInval = parametrosProfissaoCbo[0].teto
        } else {
          maximoInval = 1500000
        }
      }
      maximoSemSDPSMorte = Number(this.calculaMaximoSemDPSSeguro(maximoMorte, coberturaMorte, simuladorSeguroSettings.regra_dps).toFixed(0))
      maximoSemDPSInvalidez = Number(this.calculaMaximoSemDPSSeguro(maximoInval, coberturaInvalidez, simuladorSeguroSettings.regra_dps).toFixed(0))
      profissao = true
    } else {
      coberturaMorte = 0
      coberturaInvalidez = 0
      maximoMorte = 0
      maximoInval = 0
      minimoMorte = 0
      entradaMorte = 0
      entradaInvalidez = 0
      minimoInvalidez = 0
      maximoSemSDPSMorte = 0
      maximoSemDPSInvalidez = 0
      stepMorte = 0
      stepInvalidez = 0
      profissao = false,
        valorAtual = 0
    }
    let dadosSimuladorSeguro = {
      bloqueio: { idade: false, invalidez: false, morte: false, semCobertura: false },
      chave: chave,
      coberturaInvalidez: coberturaInvalidez,
      coberturaMorte: coberturaMorte,
      descricaoInvalidez: paramMAG_Invalidez.descricao,
      descricaoMorte: paramMAG_Morte.descricao,
      entradaInvalidez: Math.ceil(entradaInvalidez / stepMorte) * stepMorte, //arredonda sempre pra cima, considerando o valor do step
      entradaMorte: Math.ceil(entradaMorte / stepMorte) * stepMorte, //arredonda sempre pra cima, considerando o valor do step
      fatorInvalidez: fator_idade_seguro.fator_invalidez,
      fatorMorte: fator_idade_seguro.fator_morte,
      idCoberturaInvalidez: paramMAG_Invalidez.idCobertura,
      idProdutoInvalidez: simuladorSeguroSettings.invalidezid,
      idCoberturaMorte: paramMAG_Morte.idCobertura,
      idProdutoMorte: simuladorSeguroSettings.morteid,
      matricula: usuario.data.cadastro.dados_plano.matricula,
      maximoInvalidez: maximoInval === undefined ? 0 : Math.ceil(maximoInval / stepMorte) * stepMorte,
      maximoMorte: maximoMorte === undefined ? 0 : Math.ceil(maximoMorte / stepMorte) * stepMorte,
      maximoSemDpsInvalidez: maximoSemDPSInvalidez === 0 ? minimoInvalidez : maximoSemDPSInvalidez,
      maximoSemDpsMorte: maximoSemSDPSMorte === 0 ? minimoMorte : maximoSemSDPSMorte,
      Mensagens:[],
      minimoInvalidez: Math.ceil(minimoInvalidez / stepMorte) * stepMorte,
      minimoMorte: Math.ceil(minimoMorte / stepMorte) * stepMorte,
      modeloProposta: (simuladorSeguroSettings || {}).modelo_proposta || '',
      numConvenio: simuladorSeguroSettings.num_convenio,
      plano: usuario.data.cadastro.dados_plano.plano,
      prazo_cobertura_invalidez: (parametrosSimuladorMAG || {}).prazo_cobertura_invalidez || 30,
      prazo_cobertura_morte: (parametrosSimuladorMAG || {}).prazo_cobertura_morte || 30,
      profissao: profissao,
      solicita_prazo_cobertura: simuladorSeguroSettings.solicita_prazo_cobertura || false,
      stepInvalidez: paramMAG_Invalidez.capitalBase ? paramMAG_Invalidez.capitalBase : stepInvalidez,
      stepMorte: paramMAG_Morte.capitalBase ? paramMAG_Morte.capitalBase : stepMorte,
      tipo: 'Seguro',
      titulo: 'Simulador de</br>Seguro de Renda',
      uid: uid,
      valorAtual: valorAtual
    }
    if(dadosSimuladorSeguro.solicita_prazo_cobertura){
      //Não será possível simular - criar modal na tela
      if(!parametrosSimuladorMAG) {
        return false
      } else {
        if(parametrosSimuladorMAG.fator_invalidez) {
          dadosSimuladorSeguro.fatorInvalidez = parametrosSimuladorMAG.fator_invalidez
        }
        if(dadosSimuladorSeguro.fatorMorte) {
          dadosSimuladorSeguro.fatorMorte = parametrosSimuladorMAG.fator_morte
        }
      }
    }
    //ajusta valor máximo: se o participante já tem valor cobertura maior, iguala
    dadosSimuladorSeguro.maximoInvalidez = dadosSimuladorSeguro.maximoInvalidez < dadosSimuladorSeguro.coberturaInvalidez ? dadosSimuladorSeguro.coberturaInvalidez : dadosSimuladorSeguro.maximoInvalidez
    dadosSimuladorSeguro.maximoMorte = dadosSimuladorSeguro.maximoMorte < dadosSimuladorSeguro.coberturaMorte ? dadosSimuladorSeguro.coberturaMorte : dadosSimuladorSeguro.maximoMorte
    //BLOQUEIOS VERIFICAÇÃO
    if (idade < idadeLimiteContratacao.idadeMinima) {
      dadosSimuladorSeguro.bloqueio.idade = true
    }
    if (dadosSimuladorSeguro.maximoInvalidez === 0 || dadosSimuladorSeguro.maximoMorte === 0) {
      dadosSimuladorSeguro.bloqueio.semCobertura = true
    }
    if (dadosSimuladorSeguro.maximoInvalidez < dadosSimuladorSeguro.entradaInvalidez) {
      dadosSimuladorSeguro.entradaInvalidez = dadosSimuladorSeguro.coberturaInvalidez
      dadosSimuladorSeguro.bloqueio.invalidez = true
    }
    if (dadosSimuladorSeguro.maximoMorte < dadosSimuladorSeguro.entradaMorte) {
      dadosSimuladorSeguro.entradaMorte = dadosSimuladorSeguro.coberturaMorte
      dadosSimuladorSeguro.bloqueio.morte = true
    }
    return dadosSimuladorSeguro
  }

  async getParamentrosSeguro(plano){
    let ref = this.database.ref(`settings/simulador_seguro/${plano}/limite_risco`); // parametros do limites de contratação seguro
    try {
      const snapshot = await ref.once('value');
      const data = snapshot.val();
      let limite_risco = {}
      if(data) {
        limite_risco = {
          coberturaInvalidez: data.limite_idade_cobertura_invalidez,
          coberturaMorte: data.limite_idade_cobertura_morte,
          idadeMinima: data.idade_minima_contratacao,
          idadeContratacaoCoberturaInvalidez: data.idade_maxima_contratacao_invalidez,
          idadeContratacaoCoberturaMorte: data.idade_maxima_contratacao_morte,
          prazoMaximoContratacao: data.prazo_maximo_contratacao,
          prazoMinimoContratacao: data.prazo_minimo_contratacao
        };
      }
      return limite_risco;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  //28
  calculaMaximoSemDPSSeguro(setting, contratado, regra) {
    if (contratado) {
      let dps = setting > contratado ? setting : contratado
      regra.forEach(rgr => {
        let limite = contratado + rgr.limite
        let perc = contratado * (rgr.percentual / 100 + 1)
        let menorValor = perc > limite ? limite : perc
        dps = menorValor > dps ? dps : menorValor
      })
      return dps
    } else {
      return 0
    }
  }
  //INTEGRAÇÃO MAG SEGUROS FIM//

  emitirBoletoAporte(chave, origem, anoMes, valor, dataVencimento, dadosParticipante, tipoCobranca, plano, cardId) {

    let body = {
      acao: 'emitirBoletoAporte',
      chave: chave,
      origem: origem,
      anoMes: anoMes,
      valor: valor,
      dataVencimento: dataVencimento,
      dadosParticipante: dadosParticipante,
      tipoCobranca: tipoCobranca,
      plano: plano,
      cardId: cardId
    }
    return this.apiPortal({ body: body }).then((response) => {
      return response.data
    })

  }

  async cancelarContratacao(chave, id, tipo, uid) {
    if (sessionStorage.isRP === 'true' && tipo !== 'Empréstimo') {
      return false
    }
    let body = {
      acao: 'cancelarContratacao',
      chave: chave,
      id: id,
      tipo: tipo,
      uid: uid
    }
    return this.apiPortal({ body: body }).then((response) => {
      return response.data
    })
  }

  async atualizaContratacao(chave, id, objJson) {
    let body = {
      acao: 'atualizaContratacao',
      chave: chave,
      id: id,
      objJson: objJson
    }
    return this.apiPortal({ body: body }).then((response) => {
      return response.data
    })
  }

  //36
  salvarCadastro(chave, chave_interna, cadastro, origem) {
    try {
      let ref = this.database.ref(`usuarios/${chave}/${chave_interna}/`)
      ref.update(cadastro)
      return true
    }
    catch (e) {
      return false
    }
  }

  //37
  uploadNewAvatar(chave, cropData) {
    try {
      cropData.filename = 'avatar.jpg'
      cropData.croppedFile.name = 'avatar.jpg'
      var storageRef = this.storage.ref(`usuarios/${chave}/${cropData.croppedFile.name}`)
      var metadata = {
        contentType: cropData.croppedFile.type
      }
      var file = cropData.croppedFile
      storageRef.put(file, metadata).then(function (snapshot) {
        //console.log('Uploaded a blob or file!');
      })
      return true
    }
    catch (e) {
      return false
    }
  }

  uploadImage(chave, cropData, fileName) {
    var storageRef = this.storage.ref(`usuarios/${chave}/${fileName}`)
    var metadata = {
      contentType: cropData.type
    }
    return storageRef.put(cropData, metadata)
      .then(snapshot => {
        return snapshot.ref.getDownloadURL()
      }).then((url) => {
        if (fileName === 'avatar.jpg') {
          this.saveFotoBD(chave, url, true)
        }
        return url
      }).catch((e) => {
        return e
      })
  }


  //38
  async getLoginUsuario(uid) {
    let ref = this.database.ref(`login/${uid}`)
    return ref.once('value').then((data) => {
      if (data.val()) {
        return data.val()
      } else {
        return null
      }
    })
  }

  //40
  async getUsuarioChavePrincipal(uid) {
    let ref = this.database.ref(`login/${uid}/chave_principal`)
    return ref.once('value').then((data) => {
      if (data.val()) {
        return data.val()
      } else {
        return ''
      }
    })
  }

  async getUsuarioChave(uid) {
    let body = {
      acao: 'getUsuarioChave',
      uid: uid
    }
    return this.apiPortal({ body: body }).then((response) => {
      return response.data.sucesso ? response.data.data : false
    })
  }

  //41
  async getContratacao(chave, tipo, status) {
    let body = {
      acao: 'getContratacao',
      chave: chave,
      tipo: tipo,
      status: status
    }
    return this.apiPortal({ body: body }).then((response) => {
      return response.data.sucesso ? response.data.data : false
    })
  }

  async validaSeContratoAberto(chave, tipoContratacao) {
    let body = {
      acao: 'validaSeContratoAberto',
      chave: chave,
      tipo: tipoContratacao
    }
    let response = await this.apiPortal({ body: body })
    return response.data.sucesso ? response.data.data : false
  }
  //42
  solicitaDadosSinqia(chave, dadosCadastro, competencia, uid, tipo) {
    try {
      let cpf = dadosCadastro.informacoes_pessoais.cpf.replace('.', '').replace('-', '').replace('.', '')
      let body = {
        chave: chave,
        ambiente: 'PRD',
        cpf: cpf,
        matricula: dadosCadastro.dados_plano.matricula,
        data_request: utils.dateFormat(new Date(), true, false, false),
        data_adesao: dadosCadastro.dados_plano.data_adesao,
        data_competencia: competencia,
        uid: uid,
        tipo: tipo
      }
      const functions = firebase.app().functions('southamerica-east1')
      const apiSinqia = functions.httpsCallable('apiSinqia')
      return apiSinqia({ body: body })
        .catch((e) => {
          return e
        })
    } catch (e) {
      return e
    }
  }

  //44
  async getFatorSimuladorSeguro(idade, plano) {
    let ref = this.database.ref(`settings/simulador_seguro/${plano}/fator_idade/${idade}`)
    return ref.once('value').then((data) => {
      if (data.val()) {
        return data.val()
      } else {
        return { "fator_invalidez": 0.1, "fator_morte": 0.1 }
      }
    })
  }

  //45
  async getProfissaoParticipante(chave) {
    let ref = this.database.ref(`usuarios/${chave}/data/cadastro/informacoes_pessoais/profissao`)
    return ref.once('value').then((data) => {
      if (data.val()) {
        return data.val()
      } else {
        return null
      }
    })
  }

  //46
  getProfissoes() {
    let ref = this.database.ref(`settings/simulador_seguro/profissoes`)
    return ref.once('value').then((data) => {
      if (data.val()) {
        return data.val()
      } else {
        return null
      }
    })
  }

  //47
  getPerfilInvetimento(plano, perfilInvestimento) {
    let ref = this.database.ref(`settings/rentabilidade/${plano}/${perfilInvestimento}`)
    return ref.once('value').then((data) => {
      if (data.val()) {
        return data.val()
      } else {
        return null
      }
    })
  }

  //48
  getVersao() {
    let ref = this.database.ref(`settings/versao`)
    return ref.once('value').then((data) => {
      if (data.val()) {
        return data.val()
      } else {
        return null
      }
    })
  }

  //49
  getBenchmark() {
    let ref = this.database.ref(`settings/rentabilidade/benchmark`)
    return ref.once('value').then((data) => {
      if (data.val()) {
        ////console.log('data.val()',data.val())
        let ret = data.val()
        ret[0].valores = ret[0].valores.reverse() //Ibovespa
        ret[1].valores = ret[1].valores.reverse() //CDI
        ret[2].valores = ret[2].valores.reverse() //Meta Atuarial
        return ret
      } else {
        return null
      }
    })
  }

  //50
  async getRentabilidade(plano, perfilInvestimento) {
    let perfil = await this.getPerfilInvetimento(plano, perfilInvestimento)
    let obj = {
      cor: '#1E77C6',
      nome: perfilInvestimento,
      valores: perfil.valores ? perfil.valores.reverse() : '',
      composicao: perfil.Composicao
    }
    let retorno = new Array(obj)
    let benchmark = await this.getBenchmark()
    Array.prototype.push.apply(retorno, benchmark)
    return retorno
  }

  //51
  async getDocumentosGerais(plano) {
    let ref = this.database.ref(`admin/documentos_gerais`)
    return ref.once('value').then((data) => {
      if (data.val()) {
        return data.val()
      } else {
        return null
      }
    })
  }

  //52
  async gravaDataVisualizacaoDoc(docId, chave, dataVisualizacao) {
    let ref = this.database.ref(`usuarios/${chave}/data/meus_documentos/${docId}`)
    return ref.update({
      data_visualizacao: dataVisualizacao
    })
  }

  //53
  signOut() {
    if (this.auth) {
      this.auth.signOut();
    }
    sessionStorage.chave = ''
    page('/signout');
  }

  //54
  async downloadStorageFile(storageRef, callback, optionalParam) {
    try {
      let gsReference = this.storage.refFromURL(storageRef)
      let url = await gsReference.getDownloadURL()
      if (callback) {
        return callback(url, optionalParam)
      } else {
        return url
      }
    } catch (e) {
      //se cair aqui é pq ainda não fez upload da foto/avatar ou o documento não existe no storage! Seguir normalmente
      if (callback) {
        return callback(null, optionalParam)
      }
      return null
    }
  }
  // var gsReference = this.storage.refFromURL(storageRef)
  // return gsReference.getDownloadURL().then((url) => {
  //   if (callback) {
  //     return callback(url, optionalParam)
  //   } else {
  //     return url
  //   }
  // }).catch((error) => {
  //     //se cair aqui é pq ainda não fez upload da foto/avatar ou o documento não existe no storage! Seguir normalmente
  //     if (callback) {
  //       return callback(null, optionalParam)
  //     }
  //     return null
  // })


  //55
  async saveFotoBD(chave, url, atualizaPD) {
    let dados
    url = url ? url : ''
    if (atualizaPD) {
      let ref = this.database.ref(`usuarios/${chave}`)
      dados = await ref.once('value').then((snapshot) => {
        return snapshot.val()
      })
      dados.data.cadastro.informacoes_pessoais['photoUrl'] = url
      sessionStorage.saveFotoBD = 'true'
    } else {
      let ref = this.database.ref(`usuarios/${chave}/data/cadastro/informacoes_pessoais`)
      return ref.update({ photoUrl: url })
    }
  }

  //56
  setDataNivelCampanha(idCampanha, idUsuario, nivel) {
    var ref = this.database.ref(`admin/campanhas/${idCampanha}/participantes/${idUsuario}`)
    return ref.once('value').then((data) => {
      if (data.val() && data.val()[nivel] === '') {
        var date = utils.dateFormat(new Date(), false, false, true, false, false)
        let obj = {}
        obj[nivel] = date
        ref.update(obj)
        let idNivel = 0
        if (nivel == Enum.nivelCampanha.VISUALIZACAO) {
          idNivel = 1
        } else if (nivel == Enum.nivelCampanha.CLICK) {
          idNivel = 2
        } else if (nivel == Enum.nivelCampanha.CONTRATACAO) {
          idNivel = 3
        }
        if (idNivel != 0) {
          ref = firebase.database().ref(`admin/campanhas/${idCampanha}/chartData/${idNivel}`)
          ref.transaction((valor) => {
            return (valor || 0) + 1
          }, function (error) {
            if (error) {
              //Erros.registraErro(idUsuario, 'chartData-Campanha', 'atualização do gráfico', JSON.stringify(error))
            }
          })
        }
        return true
      } else {
        return null
      }
    })
  }

  //57
  validaDadosCompletosAdesao(chave) {
    let ref = this.database.ref(`usuarios/${chave}/data/cadastro/adesao_digital/cadastro_incompleto`)
    return ref.once('value').then((data) => {
      if (!data.val()) {
        return false //caso o usuario não tenha vindo da adesão esse nó não existe, então retorna false para atualizar a sessionStorade.cadastro_incompleto
      } else {
        return data.val()
      }
    })
  }

  //58
  updateDadosAdesao(chave, userData, origem, adicionaNovoParticipante) {
    let body = {
      acao: 'updateDadosAdesao',
      chave: chave,
      user: userData,
      origem: origem,
      adicionaNovoParticipante: adicionaNovoParticipante
    }

    return this.apiPortal({ body: body }).then((response) => {
      return response.data.sucesso ? response.data.data : false
    })
  }

  //59
  updateDadosCampanhaPerfil(chave, objDadosCampanhaPerfil) {
    let data = utils.dateFormat(new Date(), false, true, false, false, false)
    let periodo = data.substring(0, 4)
    let objFinal = {}
    objDadosCampanhaPerfil['periodo'] = periodo
    objFinal[data] = objDadosCampanhaPerfil
    let user = this.database.ref(`usuarios/${chave}/perfil_investimento_historico`)
    user.update(objFinal)
    return
  }

  //60
  updateDadosAdesaoStatus(chave, itemAdesao, dado) {
    let user = this.database.ref(`usuarios/${chave}/data/cadastro/adesao_digital`)
    let obj = {}
    obj[itemAdesao] = dado
    return user.update(obj)
  }

  //62
  deleteImage(chave, fileName) {
    var storageRef = this.storage.ref(`usuarios/${chave}/${fileName}`)
    return storageRef.delete().then(() => {
      return ""
    }).catch((err) => {
      //console.log("erro ao deletar o arquivo",err)
      return "ERRO"
    })
  }

  //63
  async gravarRendaMensal(chave, renda) {
    let user = this.database.ref(`usuarios/${chave}/data/cadastro/informacoes_pessoais`)
    user.update({ renda_mensal: renda })
    return true
  }

  //64
  async atualizarDataValores(chave, detalhes) {
    let invalidez = Number(detalhes.contribInvalidez)
    let morte = Number(detalhes.contribMorte)
    let user = this.database.ref(`usuarios/${chave}/data/valores`)
    user.update({ coberturaInvalidez: invalidez, coberturaMorte: morte })
    return true
  }

  //65
  async consultarSettingsPerfilInvestimentoPorPlano(plano) {
    let ref = this.database.ref(`settings/perfil_investimento/${plano}`)
    return ref.once('value').then((data) => {
      if (!data.val()) {
        return false
      } else {
        return data.val()
      }
    })
  }

  //66
  async consultarDadosCampanhaPerfilParticipante(chave) {
    let ref = this.database.ref(`usuarios/${chave}/perfil_investimento_historico`)
    return ref.orderByKey().limitToLast(1).once('value').then((snapshot) => {
      let ret = null
      snapshot.forEach((data) => {
        if (data.val()) {
          ret = data.val()
        }
      });
      return ret
    })
  }

  //67
  async gravarPerguntasDps(chave, perguntas) {
    let obj = {}
    obj = perguntas
    let user = this.database.ref(`usuarios/${chave}/integracoes/mag/api/dps`)
    user.set(obj)
      .then(() => {
        // console.log('Dados criados com sucesso!');
      })
      .catch((error) => {
        console.error('Erro ao criar os dados: ', error);
      });
  }

  async criarLinkContratacao(contratacao) {
    let body = {
      acao: 'criar',
      contratacao: contratacao
    }
    return this.apiContratacao(body).then((response) => {
      return response.data
    })
  }

  async enviarSMSContratacao(dadosSms) {
    let body = {
      acao: 'sms',
      dados: dadosSms
    }
    return this.apiContratacao(body).then((response) => {
      return response.data
    })
  }

  simulaBeneficioFiscal(request) {
    let body = request
    return this.apiPortal({ body: body }).then((response) => {
      return response.data
    })
  }

  async salvarProposta(proposta, chave, origem, idContratacao) {
    let body = {
      acao: 'salvarProposta',
      chave: chave,
      proposta: proposta,
      origem: origem,
      idContratacao: idContratacao
    }
    return this.apiPortal({ body: body }).then((response) => {
      return response.data.sucesso ? response.data.data : false
    }).catch((e) => {
      return false
    })
  }

  async getRegrasCobrancaParticipante(chave, plano, seguro) {
    let body = {
      acao: 'getRegrasCobrancaParticipante',
      chave: chave,
      plano: plano,
      seguro: seguro
    }
    let response = await this.apiPortal({ body: body })
    if (response && response.data && response.data.sucesso) {
      return response.data.sucesso ? response.data.data : false
    } else {
      return []
    }
  }

  async atualizarRegrasCobrancaParticipante(chave, regras) {
    let body = {
      acao: 'atualizarRegrasCobrancaParticipante',
      chave: chave,
      regras: regras
    }
    return this.apiPortal({ body: body }).then((response) => {
      return response.data.sucesso ? response.data.data : false
    }).catch((e) => {
      console.log('Error:',)
      return false
    })
  }

  async montarNovoProjetoVida(chave, adiOrigem, plano, valoresContratacao, novasRegras, situacaoPlano, forma) {

    let body = {
      acao: 'montarNovoProjetoVida',
      chave: chave,
      adiOrigem: adiOrigem,
      plano: plano,
      valoresContratacao: valoresContratacao,
      novasRegras: novasRegras || null,
      situacaoPlano: situacaoPlano,
      forma: forma || null
    }

    return this.apiPortal({ body: body }).then((response) => {
      return response.data.sucesso ? response.data.data : false
    }).catch((e) => {
      return false
    })
  }

  aderirMaisAmigos(chave) {
    let data = {
      acao: 'aderir',
      chave: chave
    }
    return this.apiMaisAmigos(data).then((response) => {
      if (!response.data.sucesso) {
        //Erros.registraErro(sessionStorage.uid, 'serviços', 'apiMaisAmigos', 'Consulta da apiMaisAmigos não retornou dados validos')
        return null
      } else {
        return response.data.response
      }
    }).catch((error) => {
      //Erros.registraErro(sessionStorage.uid, 'serviços', 'apiMaisAmigos', error.name + ' - ' +error.message)
      return null
    })
  }

  dadosIndicacoesMaisAmigos(chave, plano) {
    let data = {
      acao: 'dadosIndicacoes',
      chave: chave,
      plano: plano
    }
    return this.apiMaisAmigos(data).then((response) => {
      if (!response.data.sucesso) {
        //Erros.registraErro(sessionStorage.uid, 'serviços', 'apiMaisAmigos', 'Consulta da apiMaisAmigos não retornou dados validos')
        return null
      } else {
        return response.data.response
      }
    }).catch((error) => {
      //Erros.registraErro(sessionStorage.uid, 'serviços', 'apiMaisAmigos', error.name + ' - ' +error.message)
      return null
    })
  }

  linkIndicacaoMaisAmigos(chave, plano, empresa, colegas) {
    let data = {
      acao: 'linkIndicacao',
      chave: chave,
      plano: plano,
      empresaVinculada: empresa,
      colegasDeTrabalho: colegas
    }

    return this.apiMaisAmigos(data).then((response) => {
      if (!response.data.sucesso) {
        //Erros.registraErro(sessionStorage.uid, 'serviços', 'apiMaisAmigos', 'Consulta da apiMaisAmigos não retornou dados validos')
        return null
      } else {
        return response.data.response
      }
    }).catch((error) => {
      //Erros.registraErro(sessionStorage.uid, 'serviços', 'apiMaisAmigos', error.name + ' - ' +error.message)
      return null
    })
  }

  textoTermoAdesaoMaisAmigos(plano) {
    let ref = this.database.ref(`settings/mais_amigos/${plano}`)
    return ref.once('value').then((snapshot) => {
      let ret = null
      if (snapshot.val()) {
        ret = snapshot.val()
      }
      return ret
    })
  }

  async atualizarBenficiarios(chave, beneficiarios) {
    try {
      let ref = this.database.ref(`usuarios/${chave}/data/cadastro/beneficiarios`)
      ref.update(beneficiarios)
      return true
    } catch (e) {
      return false
    }
  }

  async finalizaKids(adesaoKids) {
    let body = {
      acao: 'finalizaKids',
      dadosUsuario: adesaoKids
    }
    let response = await this.apiAdesao({ body: body, metodo: 'POST' })
    if (!response.data.sucesso) {
      return null
    } else {
      return response.data.response
    }
  }

  async verificaPgto(idUsuarioAdesao) {
    let body = {
      acao: "verificaPgto",
      idUsuarioAdesao: idUsuarioAdesao,
      tipoPagamento: 'pix'
    };
    return this.apiAdesao({ body: body, metodo: "POST" })
      .then((response) => {
        if (response.data.sucesso) {
          return response.data
        } else {
          return null
        }
      }).catch((erro) => {
        return null
      })
  }

  async regerarCobranca(objCobranca, isADI) {
    let idApi = 'regerarcobranca'
    if (!isADI) {
      //vai gerar boleto JUNO sem vinculo com ADI (implantdo em 02/2022)
      //acordado com o Langa em 01/Jun/22 porque não existia mecanismo no ADI para regerar contribuições antigas da Sinqia
      idApi = 'cobranca'
      objCobranca = {
        regraCobrancaId: "",
        origem: "CONTRIBUIÇÃO",
        chave: objCobranca.chave,
        descricao: objCobranca.descricaoCobranca + ' - APP Portal',
        dataBase: objCobranca.dataBase,
        valor: objCobranca.valor,
        vencimento: objCobranca.vencimento,
        diasAposVencimento: 29,
        tipoCobranca: "BOLETO",
        nome: objCobranca.nome,
        cpf: objCobranca.cpf,
        email: objCobranca.email,
        notificar: false,
        numeroDeParcelas: 1
      }
    }
    return this.apiPrevidenciaDigital({ idApi: idApi, body: objCobranca, metodo: 'POST' })
      .then((response) => {
        return response.data.sucesso ? JSON.parse(response.data.response) : false
      }).catch((e) => {
        console.error(e);
        return false
      })
  }

  async obterLinkBoletoStockPrev(chave, cpf, transactionId, dataVencimento) {
    let idApi = 'obterlinkboletostockprev'
    let objRequest = {
      chave: chave,
      cpf: cpf,
      transactionId: transactionId,
      dataVencimento: dataVencimento || null
    }
    return this.apiPrevidenciaDigital({ idApi: idApi, body: objRequest, metodo: 'POST' })
      .then((response) => {
        return response.data.sucesso ? JSON.parse(response.data.response) : false
      }).catch((e) => {
        console.error(e);
        return false
      })
  }

  async atualizarProjetoVidaParticipante(chave, projetoVida) {
    let ref = this.database.ref(`usuarios/${chave}/adi`)
    return ref.update(projetoVida)
      .then(() => {
        return true
      }).catch((e) => {
        return false
      })
  }

  async atualizarDadosPlanoParticipante(chave, diaVencimento, dataAdesao) {
    let ref = this.database.ref(`usuarios/${chave}/data/cadastro/dados_plano`)
    return ref.update({ vencimento_cobranca: diaVencimento, data_adesao: dataAdesao })
      .then(() => {
        return true
      }).catch((e) => {
        return false
      })
  }

  async finalizaCadastroAdesao(chave) {
    let body = {
      acao: 'finalizaCadastroAdesao',
      chave: chave
    }

    let ret = false
    return this.apiAdesao({ body: body, metodo: 'POST' }) //gera Pdfs de adesão e finaliza o status/processo
      .then((response) => {
        if (response && response.data && response.data.sucesso) {
          ret = response.data.sucesso
        }
        return ret
      }).catch((e) => {
        return ret
      })
  }

  async contratacaoConfirmar(dadosContratacao, pipeline, camposPersonalizados) {
    let body = {
      acao: 'contratacaoConfirmar',
      dadosContratacao: dadosContratacao,
      uid: dadosContratacao.uid,
      pipelineOrigem: pipeline || null,
      camposPersonalizados: camposPersonalizados || null
    }
    return this.apiPortal({ body: body }).then((response) => {
      return response.data.sucesso ? response.data.data : false
    })
  }

  async confirmarResgatePD(dadosContratacao, chave) {
    let dateFormat = utils.dateFormat(new Date(), true, true, false)
    let objeto_contratacao = new Object()
    objeto_contratacao[dateFormat] = { ...dadosContratacao }
    let ref = this.database.ref(`/usuarios/${chave}/transacoes/resgates`)
    let ret = await ref.update(objeto_contratacao).then(() => {
      return true
    }).catch((e) => {
      return false
    })
    if (ret) {
      return ret ? ret : false
    } else {
      return false
    }
  }

  async simuladorGeraLead(chave, uid, origem, tipoSolicitacao, valorLead, infoAdicional, plano, pipeline, camposPersonalizados) {
    let body = {
      acao: 'simuladorGeraLead',
      tipoSolicitacao: tipoSolicitacao,
      valorLead: valorLead,
      infoAdicional: infoAdicional,
      origem: origem,
      chave: chave,
      uid: uid,
      plano: plano,
      pipeline: pipeline,
      camposPersonalizados: camposPersonalizados
    }
    let response = await this.apiPortal({ body: body })
    return response.data.sucesso ? response.data.data : false
  }

  async getParticipacoes(cpf, uid, origem) {
    const body = {
      acao: 'getParticipacoes',
      cpf: cpf,
      uidRP: uid,
      origem: origem
    }
    const response = await this.apiPortal({ body: body })
    if (!response.data.sucesso || response.data.data.length === 0) {
      return false
    }
    let participacoes = response.data.data
    for (let i = 0; i <= participacoes.length - 1; i++) {
      let avatar = participacoes[i].avatar
      if (avatar && avatar !== 'avatarOperacoes') {
        participacoes[i].avatar = await this.downloadStorageFile(avatar, null)
      }
      if (!participacoes[i].avatar) {
        participacoes[i].avatar = 'avatarGenerico'
      }
    }

    return participacoes
  }

  async validaRP(uid) {
    let body = {
      acao: 'validaRP',
      uid: uid
    }
    return this.apiPortal({ body: body }).then((response) => {
      return response.data.sucesso ? response.data.data : false
    })
  }

  async getVideos(tipo) {
    let body = {
      acao: 'getVideos',
      tipo: tipo
    }
    return this.apiPortal({ body: body }).then((response) => {
      return response.data.sucesso ? response.data.data : false
    })
  }

  async getParticipante(chave, chaveInterna, origem) {
    let body = {
      acao: 'getParticipante',
      chave: chave,
      chaveInterna: chaveInterna,
      origem: origem
    }
    return this.apiPortal({ body: body }).then((response) => {
      return response.data.sucesso ? response.data.data : false
    })

  }

  async getParticipantePorChave(chave, uid) {
    let body = {
      acao: 'getParticipantePorChave',
      chave: chave,
      uid: uid
    }
    let response = await this.apiPortal({ body: body })
    let participante = response.data.data
    if (!participante) {
      return false
    }
    if (participante.segmento) {
      sessionStorage.segmento = JSON.stringify(participante.segmento)
    }
    if ((participante.projetoVida || {}).setting) {
      sessionStorage.reservaSetting = JSON.stringify(participante.projetoVida.setting)
    }
    if(participante.elegibilidade) {
      participante.elegibilidade['beneficio'] = false
    }
    return response.data.sucesso ? participante : false
  }

  async getConfiguracaoResgatePlano(plano) {
    let ref = this.database.ref(`settings/resgate/${plano}/`)
    return ref.once('value').then((snapshot) => {
      let ret = null
      if (snapshot.val()) {
        ret = snapshot.val()
      }
      return ret
    })
  }

  async extratoParticipante(chave, email, tipo) {
    let body = {
      acao: 'extratoparticipante',
      chave: chave,
      email: email,
      tipo: tipo
    }
    const response = await this.apiPortal({ body: body })
    if (response && response.data) {
      return JSON.parse(response.data)
    } else {
      return false
    }
  }

  async obterHistoricoReservaPorChave(chave) {
    let body = {
      acao: 'obterHistoricoReservaPorChave',
      chave: chave
    }
    const response = await this.apiPortal({ body: body })
    return response.data.sucesso ? response.data.data : false
  }

  //47
  getRentabilidadePlanos() {
    let ref = this.database.ref(`settings/rentabilidade`)
    return ref.once('value').then((data) => {
      if (data.val()) {
        let obj = data.val()
        delete obj.benchmark
        let ret = []
        Object.keys(obj).forEach((key) => {
          ret.push({
            plano: key,
            perfis: Object.keys(obj[key])
          })
        })
        return ret.length > 0 ? ret : null
      } else {
        return null
      }
    })
  }

  async mensagemEntradaLida(chave, tag) {
    let ref = this.database.ref(`usuarios/${chave}/mensagens/entrada_app`)
    let msg = {
      data_visualizacao: new Date().toISOString(),
      tag: tag
    }
    await ref.update(msg)
    return msg
  }

  async getMenuSistema(plano) {
    let ref = this.database.ref(`settings/menu_sistema/${plano}`)
    let data = await ref.once('value')
    if (data.val()) {
      return data.val()
    } else {
      return null
    }
  }

  async getCardsAcessoRapido(plano) {
    let ref = this.database.ref(`settings/acesso_rapido/${plano}`)
    let data = await ref.once('value')
    if (data.val()) {
      return data.val()
    } else {
      return null
    }
  }

  async getAcessoHome(plano) {
    let ref = this.database.ref(`settings/acesso_home/${plano}`)
    let data = await ref.once('value')
    if (data.val()) {
      return data.val()
    } else {
      return null
    }
  }

  async getDadosEntidade(plano) {
    let ref = this.database.ref(`settings/dados_entidade/${plano}`)
    let data = await ref.once('value')
    if (data.val()) {
      return data.val()
    } else {
      return null
    }
  }

  async getImagensApp() {
    let ref = this.database.ref(`settings/imagens`)
    let data = await ref.once('value')
    if (data.val()) {
      return data.val()
    } else {
      return null
    }
  }

  async getTermoAceiteApp() {
    let ref = this.database.ref(`settings/termo_aceite`)
    let data = await ref.once('value')
    if (data.val()) {
      return data.val()
    } else {
      return null
    }
  }

  async getVersaoBD() {
    let ref = this.database.ref(`settings/versao_app`)
    let data = await ref.once('value')
    if (data.val()) {
      return data.val()
    } else {
      return null
    }
  }

  async getManifestJson() {
    let ref = this.database.ref(`settings/manifest`)
    let data = await ref.once('value')
    if (data.val()) {
      return data.val()
    } else {
      return null
    }
  }

  consultoriaAbrirTicket(chave, solicitacao, assunto) {
    let objAres = {
      acao: 'abrirTicket',
      body: {
        chave: chave,
        solicitacao: solicitacao,
        assunto: assunto
      }
    }
    return this.apiAresCrm(objAres).then((response) => {
      return response.data.sucesso ? response.data.response : false
    })
  }

  async getVitrine(plano) {
    let body = {
      acao: 'getVitrine',
      plano: plano
    }
    const response = await this.apiPortal({ body: body })
    return response.data.sucesso ? response.data.data : false
  }

  async gravarAceiteParceiro(parceiro_id, chave) {
    let body = {
      acao: 'gravarAceiteParceiro',
      chave: chave,
      parceiro_id: parceiro_id
    }
    const response = await this.apiPortal({ body: body })
    return response.data.sucesso ? response.data.data : false
  }

  async gravarAceiteProdutoParceiro(parceiro_id, chave, produto_id) {
    let body = {
      acao: 'gravarAceiteProdutoParceiro',
      chave: chave,
      parceiro_id: parceiro_id,
      produto_id: produto_id
    }
    const response = await this.apiPortal({ body: body })
    return response.data.sucesso ? response.data.data : false
  }

  async getcontribuicaohistorico(chave) {
    try {
      let body = {
        acao: 'obterHistoricoParticipante',
        chave: chave
      }
      const contribuicao = await this.apiPortal({ body: body })
      if (contribuicao.data.sucesso) {
        const historicoContribuicaoPaticipante = contribuicao.data.data
        const ref = this.database.ref(`usuarios/${chave}/data/valores/historicoContribuicao`);
        // Limpando o histórico de contribuições
        await ref.remove();
        // console.log("Histórico de contribuições removido com sucesso");
        // Atualizando o histórico com novos dados
        let obj = {}
        obj = historicoContribuicaoPaticipante
        await ref.set(obj);
        // console.log("Novos dados de histórico de contribuições adicionados com sucesso");
        return historicoContribuicaoPaticipante
      } else if (contribuicao.data.erro === true) {
        // se houver erro na consulta PD
        return 0
      } else {
        // se não tiver historico ainda
        return false
      }
    } catch (e) {
      /// se houver falha na API
      console.log('Error', e)
      return 0
    }
  }

  async getTipoDocumentoCadastro(plano, opcao_entrada) {
    let ref = this.database.ref(`settings/cadastro/${plano}/tipo_documento/${opcao_entrada}`)
    let data = await ref.once('value')
    if (data.val()) {
      return data.val()
    } else {
      ref = this.database.ref(`settings/cadastro/${plano}/tipo_documento/gerais`)
      data = await ref.once('value')
      if (data.val()) {
        return data.val()
      } else {
        return null
      }
    }
  }

  async getFormaPagamento(plano) {
    let ref = this.database.ref(`settings/forma_pagamento/${plano}/`)
    let data = await ref.once('value')
    if (data.val()) {
      return data.val()
    } else {
      return null
    }
  }

  async listaConveniosContaCorrente(plano) {
    let ref = this.database.ref(`settings/convenio_debito_em_conta/${plano}/`)
    let data = await ref.once('value')
    if (data.val()) {
      return data.val()
    } else {
      return null
    }
  }

  async consultaNovoFatorPorTipo(prazo, usuario, plano) {
    let nascimento
    if (!usuario.data.cadastro.informacoes_pessoais.nascimento.includes('-')) {
      let nascimentoArray = usuario.data.cadastro.informacoes_pessoais.nascimento.split('/')
      nascimento = `${nascimentoArray[2]}-${nascimentoArray[1]}-${nascimentoArray[0]}`
    } else {
      nascimento = usuario.data.cadastro.informacoes_pessoais.nascimento
    }
    let simulacao = {
      proponente: {
        tipoRelacaoSeguradoId: 1,
        nome: usuario.data.cadastro.informacoes_pessoais.nome,
          cpf: usuario.data.cadastro.informacoes_pessoais.cpf.replace('.', '').replace('.', '').replace('-', ''),
          dataNascimento: nascimento,
          profissaoCbo: usuario.data.cadastro.informacoes_pessoais.profissao.cbo,
          sexoId: usuario.data.cadastro.informacoes_pessoais.sexo === "Masculino" ? 1 : 2,
          uf: usuario.data.cadastro.endereco.estado,
          declaracaoIRId: 1
        },
        periodicidadeCobrancaId: 30,
        prazoCerto: prazo,
        prazoPagamentoAntecipado: 10,
        prazoDecrescimo: 10
      }

      let body = { simulacoes: [] }
      body.simulacoes.push({...simulacao})
      let data = {
        idApi: 'simulacao',
        body: body,
        metodo: 'POST',
        plano: plano
      }
      let response = await this.apiMAG(data)
      if(response.data.sucesso) {
        return response.data.response
      } else {
        return null
      }
  }

  async carregarParametrosBeneficios(chave) {
    let body = {
      acao: 'tiposbeneficios',
      chave: chave
    }
    let response = await this.apiPortal({ body: body })
    if(response && response.data.sucesso) {
      return JSON.parse(response.data.response)
    }
    else {
      return null
    }
  }

  async regrasBeneficioPorPlanoId(plano_id) {
    let body = {
      acao: 'obterregrasporplano',
      id: plano_id
    }
    let response = await this.apiPortal({ body: body })
    if(response && response.data.sucesso) {
      return JSON.parse(response.data.response)
    }
    else {
      return null
    }
  }

  async simularBeneficio(parametros) {
    let body = {
      acao: 'simularBeneficio',
      body: parametros
    }
    let response = await this.apiPortal({ body: body })
    if(response.data && response.data.response) {
      response.data.response = JSON.parse(response.data.response)
      return response
    } else {
      return response
    }
  }

  async listaBancos() {
    let body = {
      acao: 'listabancos'
    }
    let response = await this.apiPortal({ body: body })
    if(response.data && response.data.response) {
      response.data.response = JSON.parse(response.data.response)
      return response
    } else {
      return response
    }
  }

  async solicitacaoBeneficio(bdi_solicitacao_beneficio) {
    let body = {
      acao: 'solicitacaoBeneficio',
      body: bdi_solicitacao_beneficio
    }
    let response = await this.apiPortal({ body: body })
    if(response.data && response.data.response) {
      response.data.response = JSON.parse(response.data.response)
      return response
    } else {
      return response
    }
  }

  async calcularIRRFResgateParcial(paramentros) {
    let body = {
      acao: 'irrfcalculo',
      body: paramentros
    }
    let response = await this.apiPortal({ body: body })
    if(response.data && response.data.response) {
      response.data.response = JSON.parse(response.data.response)
      return response
    } else {
      return response
    }
  }

  async calculaLimiteLiquidoEmprestimo(valorTotalBrutoPreAprovado, valorSaldoRemanescente, objRegrasModalidadeEmprestimo, dataLiberacao) {
    let body = {
      acao: 'calculaLimiteLiquidoEmprestimo',
      valorTotalBrutoPreAprovado: valorTotalBrutoPreAprovado,
      valorSaldoRemanescente: valorSaldoRemanescente,
      objRegrasModalidadeEmprestimo: objRegrasModalidadeEmprestimo,
      dataLiberacao: dataLiberacao
    }
    let response = await this.apiSimulacao({ body: body })
    if(response.data && response.data.sucesso) {
      return JSON.parse(response.data.response).response
    } else {
      return response
    }
  }

  async calculaTotalBrutoEmprestimo(
    valorLiquidoDesejado,
    valorSaldoRemanescente,
    qtdParcelas,
    dataLiberacao,
    objRegrasModalidadeEmprestimo) {
    let body = {
      acao: 'calculaTotalBrutoEmprestimo',
      valorLiquidoDesejado: valorLiquidoDesejado,
      valorSaldoRemanescente: valorSaldoRemanescente,
      qtdParcelas: qtdParcelas,
      dataLiberacao: dataLiberacao,
      objRegrasModalidadeEmprestimo: objRegrasModalidadeEmprestimo
    }
    let response = await this.apiSimulacao({ body: body })
    if(response.data && response.data.sucesso) {
      return JSON.parse(response.data.response)
    } else {
      return response
    }
  }

  async calculaReservaFutura(reservaTotal, taxaAnual, contribParticipante, contribParticipantePlanoPatrocinado, contribPatronal, dataInicioRenda, tipoPlano, taxaCrescimentoContrib, decimoTerceiroReserva) {
    let body = {
      acao: 'calculaReservaFutura',
      reservaTotal: reservaTotal,
      taxaAnual: taxaAnual,
      contribParticipante: contribParticipante,
      contribParticipantePlanoPatrocinado: contribParticipantePlanoPatrocinado,
      contribPatronal: contribPatronal,
      dataInicioRenda: dataInicioRenda,
      tipoPlano: tipoPlano,
      taxaCrescimentoContrib: taxaCrescimentoContrib,
      decimoTerceiroReserva: decimoTerceiroReserva
    }
    let response = await this.apiSimulacao({ body: body })
    if(response.data && response.data.sucesso) {
      return JSON.parse(response.data.response)
    } else {
      return response
    }
  }

  async calculaDataInicioRenda(nascimento, idade) {
    let body = {
      acao: 'calculaDataInicioRenda',
      nascimento: nascimento,
      idade: idade
    }
    let response = await this.apiSimulacao({ body: body })
    if(response.data && response.data.sucesso) {
      return JSON.parse(response.data.response).split('T')[0]
    } else {
      return response
    }
  }

  async calculaRendaFutura(reservaTotalFutura, taxaAnual, qtdAnosRenda, tipoPlano, decimoTerceiroRenda) {
    let body = {
      acao: 'calculaRendaFutura',
      reservaTotalFutura: reservaTotalFutura,
      taxaAnual: taxaAnual,
      qtdAnosRenda: qtdAnosRenda,
      tipoPlano: tipoPlano,
      decimoTerceiroRenda: decimoTerceiroRenda
    }
    let response = await this.apiSimulacao({ body: body })
    if(response.data && response.data.sucesso) {
      return JSON.parse(response.data.response)
    } else {
      return response
    }
  }

  async calculaContribuicaoEsperada(aporteInicial, rendaEsperada, taxaAnual, tempoRecebimento, dataInicioRecebimento) {
    let body = {
      acao: 'calculaContribuicaoEsperada',
      aporteInicial: aporteInicial,
      rendaEsperada: rendaEsperada,
      taxaAnual: taxaAnual,
      tempoRecebimento: tempoRecebimento,
      dataInicioRecebimento: dataInicioRecebimento
    }
    let response = await this.apiSimulacao({ body: body })
    if(response.data && response.data.sucesso) {
      return JSON.parse(response.data.response)
    } else {
      return response
    }
  }



  async getConfiguracaoFaq(plano) {
    let ref = this.database.ref(`settings/dados_entidade/${plano}/faq`)
    return ref.once('value').then((snapshot) => {
      let ret = null
      if (snapshot.val()) {
        ret = snapshot.val()
      }
      return ret
    })
  }
}
